




















































































































































































































































#login_form {
  position: relative;

  #geetest {
    z-index: 100;
    position: absolute;
    top: 115px;
  }

  .geetest_holder.geetest_wind {
    font-size: 12px;
  }

  .geetest_holder.geetest_wind {
    width: 220px;
    min-width: 220px;
  }

  .el-input__suffix-inner {
    i {
      font-size: 13px;
      margin-right: 2px;
    }
  }

  .el-input__suffix {

    .el-icon-circle-check,
    .el-icon-circle-close {
      display: none;
    }
  }

  .error-enter-active,
  .error-leave-active {
    transition: opacity .5s
  }

  .error-enter,
  .error-leave-active {
    opacity: 0
  }

  .error_panel {
    transition: .2s;
    width: 220px;
    height: 34px;
    background-color: rgba(233, 70, 75, .1);
    border-radius: 4px;
    /*opacity: 0.1;*/
    color: #e94637;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    position: absolute;
    bottom: 89px;
  }

  .login-btn {
    width: 220px;
    height: 42px;
    background-image: linear-gradient(-22deg,
      #28cccc 0%,
      #73e2e2 100%);
    border-radius: 4px;
    margin-top: 58px;
  }
}

